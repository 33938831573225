import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { VueCookieNext } from 'vue-cookie-next'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css'
import FileUpload from 'primevue/fileupload'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import SelectButton from 'primevue/selectbutton'
import Message from 'primevue/message'
import Dialog from 'primevue/dialog'
import Tooltip from 'primevue/tooltip'
import ProgressSpinner from 'primevue/progressspinner'
import Textarea from 'primevue/textarea'
import Checkbox from 'primevue/checkbox'
import RadioButton from 'primevue/radiobutton'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import PrimeVue from 'primevue/config'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

export const app = createApp(App)

app.use(PrimeVue).use(VueCookieNext).use(store).use(router).use(ToastService).mount('#app')

app.component('FileUpload', FileUpload)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('InputText', InputText)
app.component('Button', Button)
app.component('SelectButton', SelectButton)
app.component('Message', Message)
app.component('Dialog', Dialog)
app.component('ProgressSpinner', ProgressSpinner)
app.component('Textarea', Textarea)
app.component('Checkbox', Checkbox)
app.component('RadioButton', RadioButton)
app.component('Toast', Toast)
app.directive('Tooltip', Tooltip)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
