import { createStore } from 'vuex'
import { min, max, median } from 'mathjs'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as utils from '@/utils'
import { showToast, Toasts } from '@/utils/toast'

type MetricImperial = 'Auto' | 'EU (metric)' | 'USA (imperial)'

interface State {
  pleaseLoad: string
  files: any[]
  coordinates: {
    Latitude: number
    Longitude: number
    Altitude: number
    fileName: string
  }[]
  unit: MetricImperial
  units: {
    [R in MetricImperial]: {
      [P in Column]: {
          Unit: string;
          Digits: number;
      };
    };
  };
}

const store = createStore({
  state: {
    pleaseLoad: 'Please load *.dtl, *.db renovation files',
    files: [],
    coordinates: [],
    unit: 'Auto',
    units: {
      Auto: {
        Distance: {
          Unit: 'm',
          Digits: 2
        },
        Speed: {
          Unit: 'm/min.',
          Digits: 2
        },
        Pressure: {
          Unit: 'bar',
          Digits: 3
        },
        'Air temperature': {
          Unit: '°C',
          Digits: 1
        },
        AirTemperature: {
          Unit: '°C',
          Digits: 1
        },
        'UV LED temperature': {
          Unit: '°C',
          Digits: 1
        },
        UVLEDTemperature: {
          Unit: '°C',
          Digits: 1
        },
        Liner: {
          Unit: '°C',
          Digits: 1
        },
        IR: {
          Unit: '°C',
          Digits: 1
        },
        IR1: {
          Unit: '°C',
          Digits: 1
        },
        IR2: {
          Unit: '°C',
          Digits: 1
        },
        System: {
          Unit: '',
          Digits: 0
        }
      },
      'EU (metric)': {
        Distance: {
          Unit: 'm',
          Digits: 2
        },
        Speed: {
          Unit: 'm/min.',
          Digits: 2
        },
        Pressure: {
          Unit: 'bar',
          Digits: 3
        },
        'Air temperature': {
          Unit: '°C',
          Digits: 1
        },
        AirTemperature: {
          Unit: '°C',
          Digits: 1
        },
        'UV LED temperature': {
          Unit: '°C',
          Digits: 1
        },
        UVLEDTemperature: {
          Unit: '°C',
          Digits: 1
        },
        Liner: {
          Unit: '°C',
          Digits: 1
        },
        IR: {
          Unit: '°C',
          Digits: 1
        },
        IR1: {
          Unit: '°C',
          Digits: 1
        },
        IR2: {
          Unit: '°C',
          Digits: 1
        },
        System: {
          Unit: '',
          Digits: 0
        }
      },
      'USA (imperial)': {
        Distance: {
          Unit: 'ft',
          Digits: 2
        },
        Speed: {
          Unit: 'ft/min.',
          Digits: 2
        },
        Pressure: {
          Unit: 'psi',
          Digits: 2
        },
        'Air temperature': {
          Unit: '°F',
          Digits: 1
        },
        AirTemperature: {
          Unit: '°F',
          Digits: 1
        },
        'UV LED temperature': {
          Unit: '°F',
          Digits: 1
        },
        UVLEDTemperature: {
          Unit: '°F',
          Digits: 1
        },
        Liner: {
          Unit: '°F',
          Digits: 1
        },
        IR: {
          Unit: '°F',
          Digits: 1
        },
        IR1: {
          Unit: '°F',
          Digits: 1
        },
        IR2: {
          Unit: '°F',
          Digits: 1
        },
        System: {
          Unit: '',
          Digits: 0
        }
      }
    }
  },
  mutations: {
    unit: (state: State, value: MetricImperial) => localStorage.unit = state.unit = value,
    readFiles: async (state: State, { data, name, size }: NewFile) => {
      const d = await utils.parseData(data, name.split('.').pop())
      if (!d.length) {
        showToast('The uploaded file contains no data.', Toasts.Information)
        return
      }
      const dat: any[] = d.map((field: any) => {
        const obj: any = {
        }
        localStorage.getItem("fields")?.split(",").forEach((columnName: string) => {
          if (field[columnName] !== undefined)
            obj[columnName === 'dateTime' ? columnName : columnName.split(' ').map((text: string) => text.charAt(0).toUpperCase() + text.slice(1)).join('')] = field[columnName];
        })
        return obj
      })
      try {
        const med = median(dat.map((d: Obj) => d.Liner) || [0])
        if (med === 0 || med === 32)
          dat.forEach((row: Obj) => delete row.Liner)
      } catch {
      }
      const start = dat[0].dateTime
      const end = dat[dat.length - 1].dateTime
      const { Latitude, Longitude, Altitude } = d[d.length - 1];
      state.files.unshift({
        data: dat,
        Latitude,
        Longitude,
        Altitude,
        selectedCols: localStorage.buttons?.split(',') || utils.buttons(dat),
        project: '',
        address: await (async () => {
          if (Latitude && Longitude && Altitude) {
            state.coordinates.push({ Latitude, Longitude, Altitude, fileName: name })
            const data = (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${Latitude},${Longitude}&format=json`)).json())[0]
            if (data) {
              const { label } = (await (await fetch(`https://nominatim.openstreetmap.org/lookup?osm_ids=${data?.osm_type[0].toUpperCase()}${data?.osm_id}&format=geocodejson&accept-language=pl`)).json()).features[0].properties.geocoding
              return label
            }
          }
          return undefined
        })(),
        manhole: {
          lower: '',
          upper: ''
        },
        head: {
          type: '',
          serial: ''
        },
        liner: {
          type: '',
          id: '',
          diameter: '',
          thickness: '',
          length: '',
          method: ''
        },
        resin: {
          type: '',
          batchID: ''
        },
        calibrationTube: [],
        name: name.replace(/\.[^.]+$/, ''),
        size,
        duration: end - start,
        start,
        end,
        loading: false,
        contractor: '',
        logo: '',
        selected: false
      })
    },
    updateButtons: (state: State, selectedCols: string[]) => localStorage.buttons = selectedCols,
    createPDF: (state: State, data: File) => {
      let usaValues:any;
        const maximum = (column: string) => {
          try
          {
            return max(data.data.map((d: Obj) => d[column]))
          }
          catch{
            return false;
          }
        }

        try {
          if (state.unit === 'Auto')
          {          
            usaValues = ( maximum('Pressure') >= 2 ) || ( maximum('UVLEDTemperature') > 85 ) || ( maximum('UV LED temperature') > 85 );
            state.units.Auto = state.units[usaValues ? 'USA (imperial)' : 'EU (metric)']
          }
          else if ((state.unit === 'USA (imperial)') !== usaValues)
            showToast(`Check units (probably shouldn't be "${state.unit}").`, Toasts.Warning, 5000)
        } catch {
        }
        data.loading = true

        const chartDiv = document.createElement('DIV')

        chartDiv.style.height = '100%'

        document.getElementById('chart')?.appendChild(chartDiv)

        const chart = am4core.create(chartDiv, am4charts.XYChart)

        chart.colors.list = [
            am4core.color('#888'),
            am4core.color('#F0F'),
            am4core.color('#088'),
            am4core.color('#FA0'),
            am4core.color('#F00'),
            am4core.color('#080'),
            am4core.color('#00F')
        ]

        // Relative time
        // data.data.forEach((_: Obj, i: number) => data.data[data.data.length - i - 1].dateTime = (data.data[data.data.length - i - 1].dateTime - data.data[0].dateTime))

        const dateAxis = new am4charts.DateAxis()
        chart.data = data.data
        chart.dateFormatter.timezoneOffset = 0
        chart.xAxes.push(dateAxis)
        chart.yAxes.push(new am4charts.ValueAxis())
        chart.legend = new am4charts.Legend()

        const exportedCols: Column[] = []

        data.selectedCols.forEach((col: Column) => Object.keys(data.data[0]).filter((c: string) => c === col).length ? exportedCols.push(col) : void 0)

        exportedCols.forEach((name: string) => {
            const series = chart.series.push(new am4charts.LineSeries())
            series.dataFields.valueY = name
            series.dataFields.dateX = 'dateTime'
            series.name = name
            series.strokeWidth = 3
        })

        const projectText: {
          text?: string;
          fontSize?: number;
          margin?: number[];
        } = data.project ? {
            text: utils.trimText(data.project, 28),
            fontSize: 15,
            margin: [0, 5, 0, 0]
        } : { }

        chart.events.on('ready', () => Promise.all([
            chart.exporting.pdfmake,
            chart.exporting.getImage('png')
        ]).then((res: (string | any)[]) => {
            const label = (name: string, data: string | undefined, max: number) => {
              if (data)
                return {
                  text: ( name && data ) ? `${name}: ${utils.trimText(data, max)}` : `${utils.trimText(data, max)}`
                }
            }
            chartDiv.remove()
            const abc = res[0];
            showToast('The report has been prepared.', Toasts.Information)
            res[0].createPdf({
                background: (() => data.logo ? {
                    image: data.logo,
                    fit: [500, 40],
                    alignment: 'right',
                    margin: [ 0, 15, 10, 0 ]
                } : { })(),
                watermark: {
                    text: 'Sewertronics',
                    angle: -45,
                    color: 'indigo',
                    opacity: 0.03,
                    bold: true
                },
                info: {
                    title: data.name.replace(/\.[^.]+$/, '.pdf'),
                    author: 'Sewertronics',
                    subject: 'Sewertronics',
                    keywords: 'Sewertronics',
                    creator: 'Sewertronics',
                    producer: 'Sewertronics',
                },
                pageSize: 'A4',
                pageOrientation: 'landscape',
                pageMargins: [30, 30, 30, 30],
                footer: (currentPage: number, pageCount: number) => ({
                    columns: [
                        '',
                        {
                            text: `${currentPage.toString()} of ${pageCount}`,
                            alignment: 'center',
                        },
                        {
                            text: `${utils.trimText(data.name, 22)}.dtl`,
                            alignment: 'right',
                            margin: [ 0, 0, 20, 0 ]
                        }
                    ]
                }),
                header: () => [
                    {
                        columns: [
                            {
                                text: `${utils.getDateTime(data.start as unknown as Date)} - ${utils.getDateTime(data.end as unknown as Date, 'HH:mm:ss')} (${utils.getTime(data.duration)})`,
                                alignment: 'left',
                                margin: [ 20, 0, 0, 0 ]
                            },
                            {
                                text: utils.trimText(data.contractor, 25),
                                alignment: 'center',
                            },
                            {
                            }
                        ],
                        margin: [ 0, 15, 0, 0 ]
                    }
                ],
                content: [
                    {
                        text: 'Renovation report',
                        alignment: 'center',
                        fontSize: 20,
                        bold: true,
                        margin: [0, 10, 0, 10]
                    },
                    {
                        columns: [
                            {
                                stack: [
                                    projectText,
                                    {
                                        text: (() => {
                                            if (!data.address?.length)
                                              return ''
                                            const address = data.address.split('\n')
                                            address.splice(3)
                                            return address.map((line: string) => utils.trimText(line, 35)).join('\n')
                                        })(),
                                        fontSize: 12,
                                    }
                                ],
                                fontSize: 15
                            },
                            {
                                stack: [
                                    label('Manhole lower', data.manhole.lower, 27),
                                    label('Manhole upper', data.manhole.upper, 27),
                                    label(`Head type`, data.head.type, 30),
                                    label(`Serial number`, data.head.serial, 28),
                                    label(`Liner brand/type`, data.liner.type, 27),
                                    label(`Liner ID`, data.liner.id, 27),
                                    label(`Liner diameter`, data.liner.diameter, 27),
                                    label(`Liner thickness`, data.liner.thickness, 27),
                                    label(`Liner length`, data.liner.length, 27),
                                    label(`Liner method`, data.liner.method, 27),
                                    label(`Resin brand/type`, data.resin.type, 27),
                                    label(`Resin batch #ID`, data.resin.batchID, 27),
                                    label(``, data.calibrationTube?.[0], 27)
                                ],
                                alignment: 'right',
                                fontSize: 12
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                width: '*',
                                layout: 'lightHorizontalLines',
                                table: {
                                    headerRows: 1,
                                    widths: [ '*', '*', '*', '*' ],
                                    body: [
                                        [
                                            '',
                                            { text: 'Minimum', bold: true },
                                            { text: 'Median', bold: true },
                                            { text: 'Maximum', bold: true }
                                        ],
                                        ...exportedCols.map((column: Column) => {
                                            const format = (value: number) => {
                                              try
                                              {
                                                return `${value.toFixed(state.units[state.unit][column].Digits)} ${state.units[state.unit][column].Unit}`    
                                              } 
                                              catch 
                                              {
                                                  return value
                                              }
                                            }
                                            const ddd = data.data.map((d: Obj) => d[column])
                                            let minimum = min(ddd)
                                            let med = median(ddd)
                                            let maximum = max(ddd)
                                            if (column === 'Distance') {
                                                maximum -= minimum
                                                minimum = '-'
                                                med = '-'
                                            }
                                            if (column === 'Speed' && ( usaValues || state.unit === 'USA (imperial)' )) {
                                              //1 meter = 3.2808399 feet (3 feet 3⅜ inches)           1 foot = 0.3048 meters
                                              minimum = minimum * 3.2808399;
                                              med = med * 3.2808399;
                                              maximum = maximum * 3.2808399;
                                            }
                                            return [column, format(minimum), format(med), format(maximum)]
                                        })
                                    ]
                                },
                            }
                        ],
                        margin: [0, 20, 0, 20]
                    },
                    {
                        image: res[1],
                        width: 770,
                        height: 360 - exportedCols.length * 15 - (data.project.length ? 20 : 0)
                    }
                ]
            }).download(`${data.name}.pdf`, () => {
              data.loading = false
              showToast('The report has been downloaded.')
            })
        }))
    },
    selectUnselectFile: (state: State, index: number) => {
      state.files[index].selected = !state.files[index].selected
    },
    deleteFile: (state: State, index: number) => {
      state.files.splice(index, 1)
      if (state.files.length < 2)
        state.files.forEach((file: File) => file.selected = false)
      showToast('The report has been removed.', Toasts.Information)
    },
  },
  actions: {
    deleteAllFiles: ({ getters, state }: any, showMessage: boolean = true) => {
      if (getters.selectedFiles)
        state.files = state.files.filter(({ selected }: File) => !selected)
      else state.files.length = 0
      if (showMessage)
        showToast('The all reports have been removed.', Toasts.Information)
    },
    mergeFiles: ({ dispatch, state }: any) => {
      const selectedFiles = state.files.filter(({ selected }: File) => selected).sort((file1: File, file2: File) => file1.start - file2.start)
      if (selectedFiles) {
        const start = Math.min(...selectedFiles.map(({ start }: File) => start));
        const end = Math.max(...selectedFiles.map(({ end }: File) => end));
        state.files.unshift({
          ...selectedFiles[0],
          name: `${selectedFiles[0].name}_merged`,
          data: selectedFiles.reduce((dat: Data, { data, selected }: File, index: number) => dat.concat(index > 0 && selected ? data : []), selectedFiles[0].data),
          selected: false,
          size: selectedFiles.reduce((size: number, file: File) => size + file.size, 0),
          duration: end - start,
          start,
          end,
        })
        dispatch('deleteAllFiles', false)
        showToast('The reports have been merged.')
      }
    }
  },
  getters: {
    isDisabled: (state: State) => state.files.filter((file: File) => file.loading).length,
    selectedFiles: (state: State) => state.files.reduce((num: number, file: File) => num + (file.selected as unknown as number), 0),
    userPass: () => {
      try {
        return atob(localStorage.getItem('userPass') || "").split(":")
      } catch {
        return [];
      }
    }
  }
} as { state: State })

if (!localStorage.unit)
  localStorage.unit = Object.keys(store.state.units)[0]
store.state.unit = localStorage.unit

export default store
